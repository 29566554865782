/* eslint-disable max-statements-per-line */
import * as React from 'react'
import PropTypes from 'prop-types'
import { CaptionHeaderText, CaptionWrapper, ScreenshotContainer, ScreenshotImageWrapper } from './Screenshot.styles'
import GSText from '../gsText/GSText'

const Screenshot = ({ screenshotUrl, captionHeader, caption, isScreenshotLeft }) => {
  const [isImageLoaded, setIsImageLoaded] = React.useState(false)

  return (
    <ScreenshotContainer isScreenshotLeft={ isScreenshotLeft } style={ { display: isImageLoaded ? 'inherit' : 'none' } }>
      <ScreenshotImageWrapper>
        <img
          src={ screenshotUrl } alt="" width="100%" onLoad={ () => setIsImageLoaded(true) }
        />
      </ScreenshotImageWrapper>
      { (caption || captionHeader) &&
      <CaptionWrapper>
        { captionHeader &&
          <CaptionHeaderText>
            <GSText size="m" isHeader isLightTheme>
              {captionHeader}
            </GSText>
          </CaptionHeaderText>
        }
        <GSText size="s" isLightTheme>
          { caption }
        </GSText>
      </CaptionWrapper>
      }
    </ScreenshotContainer>
  )
}

Screenshot.propTypes = {
  screenshotUrl: PropTypes.string.isRequired,
  captionHeader: PropTypes.string,
  caption: PropTypes.string,
  isScreenshotLeft: PropTypes.bool,
}

Screenshot.defaultProps = {
  isScreenshotLeft: true,
}

export default Screenshot
