import * as React from 'react'
import PropTypes from 'prop-types'
import { MobileDownloadButtonContainer, MobileDownloadButtonsContainer } from './MobileDownloadButtons.styles'
import AppleAppStoreLogo from '../../assets/images/AppleAppStore/apple-app-store-logo.svg'
import GooglePlayStoreLogo from '../../assets/images/GooglePlayStore/google-play-badge.png'

const renderDownloadButtons = (appStoreUrl, playStoreUrl) => (
  <>
    { appStoreUrl &&
      <a href={ appStoreUrl }>
        <MobileDownloadButtonContainer>
          <img src={ AppleAppStoreLogo } alt="" height={ 53 } />
        </MobileDownloadButtonContainer>
      </a>
    }
    { playStoreUrl &&
      <a href={ playStoreUrl }>
        <MobileDownloadButtonContainer>
          <div style={ { margin: -12 } }>
            <img src={ GooglePlayStoreLogo } alt="" height={ 80 } />
          </div>
        </MobileDownloadButtonContainer>
      </a>
    }
  </>
)

const renderComingSoon = comingSoonMessage => (
  <div>{comingSoonMessage}</div>
)

const MobileDownloadButtons = ({ appStoreUrl, playStoreUrl, comingSoonMessage }) => (
  <MobileDownloadButtonsContainer>
    { (appStoreUrl || playStoreUrl) ?
      renderDownloadButtons(appStoreUrl, playStoreUrl) :
      renderComingSoon(comingSoonMessage)
    }
  </MobileDownloadButtonsContainer>
)

MobileDownloadButtons.propTypes = {
  appStoreUrl: PropTypes.string,
  playStoreUrl: PropTypes.string,
  comingSoonMessage: PropTypes.string,
}

MobileDownloadButtons.defaultProps = {
  comingSoonMessage: 'Coming soon to the Apple App Store and Google Play Store',
}

export default MobileDownloadButtons
