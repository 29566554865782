import * as React from 'react'
import { Route, Routes } from 'react-router'
import HomePage from '../../pages/home/home.page'
import LoafProductPage from '../../pages/loaf/loaf.product.page'
import PrivacyPolicyPage from '../../pages/loaf/privacy.policy/privacy.policy.page'

const AppNavigator = () => (
  <Routes>
    <Route path="/loaf" element={ <LoafProductPage /> } />
    <Route path="/loaf/privacy-policy" element={ <PrivacyPolicyPage /> } />
    <Route path="/" element={ <HomePage /> } />
  </Routes>
)

export default AppNavigator
