import * as React from 'react'
import PropTypes from 'prop-types'
import { StyledNavLink } from './NavLink.styles'

const NavLink = ({ to = '/', children, ...props }) => (
  <StyledNavLink
    to={ to }
    { ...props }>
    {children}
  </StyledNavLink>
)

NavLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
}

export default NavLink
