import * as React from 'react'
import { HomePageContainer, LearnMoreWrapper, HomePageScreenshot, Tagline } from './home.page.styles'
import screenshot from '../../assets/images/loaf/Loaf_iPhoneX_Home_Screen_11_19_21.png'
import { useNavigate } from 'react-router';
import Button from '../../components/button/Button';
import GSText from '../../components/gsText/GSText';

const HomePage = () => {
  const navigate = useNavigate();
  
  return (
    <HomePageContainer>
      <div>
        <Tagline>
          <GSText size="xxl" isLightTheme>
            Introducing Loaf:
          </GSText>
        </Tagline>
        <GSText size="xl" isLightTheme>
          A whole new way to manage your grocery list
        </GSText>
        <LearnMoreWrapper>
          <Button onClick={ () => navigate('/loaf') }>
            Learn More
          </Button>
        </LearnMoreWrapper>
      </div>
      <HomePageScreenshot src={ screenshot } alt="" />
    </HomePageContainer>
  )
}

export default HomePage
