import * as React from 'react'
import PropTypes from 'prop-types'
import { StyledText } from './GSText.styles'

const getConstantFontSize = size => {
  switch (size) {
    case 's':
      return 0.2
    case 'm':
      return 0.6
    case 'l':
      return 1
    case 'xl':
      return 1.4
    case 'xxl':
      return 1.8
  
    default:
      break;
  }
}

const getFontWeight = (isHeader, isHeavyHeader) => {
  if (isHeader) {
    if (isHeavyHeader) {
      return 800
    }

    return 600
  }

  return 400
}

const GSText = ({ size, isHeader, isHeavyHeader, isLightTheme, children }) => (
  <StyledText size={ getConstantFontSize(size) } fontWeight={ getFontWeight(isHeader, isHeavyHeader) } isLightTheme={ isLightTheme }>
    { children }
  </StyledText>
)

GSText.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['s', 'm', 'l', 'xl', 'xxl']),
  isHeader: PropTypes.bool,
  isHeavyHeader: PropTypes.bool,
  isLightTheme: PropTypes.bool,
}

GSText.defaultProps = {
  size: 's',
  isHeader: false,
  isHeavyHeader: false,
  isLightTheme: false,
}

export default GSText
