import styled from 'styled-components';

export const HomePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  overflow: hidden;
  margin-bottom: -32px;
`

export const Tagline = styled.div`
  padding-top: 3vh;
`

export const LearnMoreWrapper = styled.div`
  padding: 3vh 0 0 0;
  display: inline-block;
`

export const HomePageScreenshot = styled.img`
  max-width: 550px;
  width: 90vw;
`
