import styled from 'styled-components'
import { Theme1 } from '../../theme/theme'

export const StyledButton = styled.div`
  background-color: ${Theme1.colors.background.primary};
  padding: 16px 24px;
  border-radius: 25px;
  color: ${Theme1.colors.text.defaultLight};
  cursor: pointer;
  &:hover {
    opacity: ${Theme1.linkHoverOpacity};
  }
`
