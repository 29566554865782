import * as React from 'react'
import './App.css'
import { AppContent, AppWrapper } from './App.styles'
import Banner from './components/banner/Banner'
import {
  BrowserRouter as Router,
} from 'react-router-dom'
import AppNavigator from './navigation/appNavigator/AppNavigator'
import ScrollToTop from './navigation/utils/ScrollToTop'

function App() {
  return (
    <Router>
      <ScrollToTop />
      <AppWrapper>
        <Banner />
        <AppContent>
          <AppNavigator />
        </AppContent>
      </AppWrapper>
    </Router>
  )
}

export default App
