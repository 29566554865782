import * as React from 'react'
import PropTypes from 'prop-types'
import { Theme1 } from '../theme/theme'

const SvgBreadLoaf = ({ size = 50, color = Theme1.colors.text.defaultLight }) => {
  return (
    <svg
      x="0px" y="0px"
      width={ size } height={ size } viewBox="0 0 256 104" enableBackground="new 0 0 256 104" xmlSpace="preserve">
      <path
        d="M226.437,101.711c13.25,0,24.993-9.411,27.061-22.493c0.335-2.077,0.502-4.184,0.502-6.31
	c0-25.771-24.6-48.303-61.336-60.647c0,0-18.728,30.517-18.733,30.525c-2.906,4.735-10.529,1.577-9.125-3.868l8.229-32.012
	c-8.062-1.732-16.508-3.012-25.259-3.78l-24.324,39.66c-1.061,1.731-3.176,2.677-5.175,2.304c-2.785-0.518-4.659-3.423-3.95-6.173
	l9.411-36.629c-9.401,0.157-18.526,0.935-27.287,2.205L72.944,42.796c-0.919,1.499-2.633,2.425-4.391,2.381
	c-3.171-0.08-5.505-3.184-4.714-6.25L71.329,9.79C30.212,21.415,2,45.306,2,72.908c0,2.126,0.167,4.233,0.492,6.31
	c2.077,13.082,13.821,22.493,27.07,22.493H226.437z"
        fill={ color }
      />
    </svg>
  )
}

SvgBreadLoaf.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

export default SvgBreadLoaf
