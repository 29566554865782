import * as React from 'react'
import { PrivacyPolicyPageContainer } from './privacy.policy.page.styles'

const PrivacyPolicyPage = () => (
  <PrivacyPolicyPageContainer>
    <h2>Privacy Policy</h2>
    <p>
      Ventric Technologies LLC ("Ventric Technologies", "we", "us", or "our") built the Loaf app as
      an Ad Supported app. This SERVICE is provided by
      Ventric Technologies at no cost and is intended for use as
      is.
    </p>
    <p>
      This page is used to inform visitors regarding our
      policies with the collection, use, and disclosure of Personal
      Information if anyone decided to use our Service.
    </p>
    <p>
      If you choose to use our Service, then you agree to
      the collection and use of information in relation to this
      policy. The Personal Information that we collect is
      used for providing and improving the Service. We will not use or share your information with
      anyone except as described in this Privacy Policy.
    </p>
    <p><strong>Information Collection</strong></p>
    <p>
      For a better experience, while using our Service, we
      may require you to provide us with certain personally
      identifiable information, including but not limited to your email. The information that
      we request will be retained by us and used as described in this privacy policy.
    </p>
    <p><strong>Email</strong></p>
    <p>
      Your email will be exclusively used for creating, accessing, recovering, and identifying your account.
      If you send us an email for support or other feedback, that email will be retained for quality
      assurance purposes. Your email will only be used to reply to the concerns or suggestions raised and
      will never be used for any marketing purposes.
    </p>
    <div>
      <p><strong>Third Party Services</strong></p>
      <p>
        The app does use third party services that may collect
        information used to identify you.
      </p>
      <p>
        Link to privacy policy of third party service providers used
        by the app
      </p>
      <ul>
        <li>
          <a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">
            Google Play Services
          </a>
        </li>
        <li>
          <a
            href="https://support.google.com/admob/answer/6128543?hl=en"
            target="_blank"
            rel="noopener noreferrer">
            AdMob
          </a>
        </li>
        <li><a href="https://expo.io/privacy" target="_blank" rel="noopener noreferrer">Expo</a></li>
        <li><a href="https://sentry.io/privacy/" target="_blank" rel="noopener noreferrer">Sentry</a></li>
        <li><a href="https://world.openfoodfacts.org/terms-of-use" target="_blank" rel="noopener noreferrer">Open Food Facts</a></li>
      </ul>
    </div>
    <p><strong>Log Data</strong></p>
    <p>
      We want to inform you that whenever you
      use our Service, in a case of an error in the app
      we collect data and information (through third party
      products) on your phone called Log Data. This Log Data may
      include information such as your device Internet Protocol
      (“IP”) address, device name, operating system version, the
      configuration of the app when utilizing our Service,
      the time and date of your use of the Service, and other
      statistics.
    </p>
    <p><strong>Cookies</strong></p>
    <p>
      Cookies are files with a small amount of data that are
      commonly used as anonymous unique identifiers. These are sent
      to your browser from the websites that you visit and are
      stored on your device's internal memory.
    </p>
    <p>
      This Service does not use these “cookies” explicitly. However,
      the app may use third party code and libraries that use
      “cookies” to collect information and improve their services.
      You have the option to either accept or refuse these cookies
      and know when a cookie is being sent to your device. If you
      choose to refuse our cookies, you may not be able to use some
      portions of this Service.
    </p>
    <p><strong>Service Providers</strong></p>
    <p>
      We may employ third-party companies and
      individuals due to the following reasons:
    </p>
    <ul>
      <li>To facilitate our Service;</li>
      <li>To provide the Service on our behalf;</li>
      <li>To perform Service-related services; or</li>
      <li>To assist us in analyzing how our Service is used.</li>
    </ul>
    <p>
      We want to inform users of this Service
      that these third parties have access to your Personal
      Information. The reason is to perform the tasks assigned to
      them on our behalf. However, they are obligated not to
      disclose or use the information for any other purpose.
    </p>
    <p><strong>Camera Usage</strong></p>
    <p>
      We want to inform you that your camera might be accessed
      for some features.  We will always ask permission before
      accessing any sensitive information or any feature that might
      be capable of collecting sensitive information, and this
      includes accessing your camera. Currently, Loaf will only use
      your camera to scan barcode.  It will not capture any images or
      videos nor will it access any images or videos from your
      device's media library. This feature is completely optional and
      you can retract any permissions granted at anytime.
    </p>
    <p><strong>Security</strong></p>
    <p>
      We value your trust in providing us your
      Personal Information, thus we are striving to use commercially
      acceptable means of protecting it. But remember that no method
      of transmission over the internet, or method of electronic
      storage is 100% secure and reliable, and we cannot
      guarantee its absolute security.
    </p>
    <p><strong>Changes to This Privacy Policy</strong></p>
    <p>
      We may update our Privacy Policy from
      time to time. Thus, you are advised to review this page
      periodically for any changes. We will
      notify you of any changes by posting the new Privacy Policy on
      this page.
    </p>
    <p>This policy is effective as of 2022-11-22</p>
    <p><strong>Contact Us</strong></p>
    <p>
      If you have any questions or suggestions about our
      Privacy Policy, please contact us
      at <a href="mailto:aaron.perdew@ventrictechnologies.com">aaron.perdew@ventrictechnologies.com</a>.
    </p>
  </PrivacyPolicyPageContainer>
)

export default PrivacyPolicyPage
