import styled from 'styled-components'
import { Theme1 } from '../../theme/theme'

export const MenuItemWrapper = styled.div`
  padding: 12px 0;
`

export const MenuItemContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  &:hover {
    opacity: ${Theme1.linkHoverOpacity};
  }
`

export const MenuItemIconWrapper = styled.div`
  padding-right: 14px;
`
