import * as React from 'react'
import PropTypes from 'prop-types'
import NavLink from '../navlink/NavLink'
import { MenuItemContentWrapper, MenuItemIconWrapper, MenuItemWrapper } from './Menu.styles'

const MenuItem = ({ to, icon, text, onClick }) => (
  <MenuItemWrapper>
    <NavLink to={ to } className="menu-item" onClick={ onClick }>
      <MenuItemContentWrapper>
        <MenuItemIconWrapper>
          {icon}
        </MenuItemIconWrapper>
        {text}
      </MenuItemContentWrapper>
    </NavLink>
  </MenuItemWrapper>
)

MenuItem.propTypes = {
  to: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.node,
  onClick: PropTypes.func,
}

export default MenuItem
