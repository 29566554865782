import styled from 'styled-components'

export const MobileDownloadButtonsContainer = styled.div`
  padding: 24px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

export const MobileDownloadButtonContainer = styled.div`
  padding: 4px;
`
