import { faHouse } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { slide as MenuComponent } from 'react-burger-menu'
import SvgBreadLoaf from '../../icons/SvgBreadLoaf'
import { Theme1 } from '../../theme/theme'
import MenuItem from './MenuItem'
import './Menu.styles.css'

const styles = {
  menuWrapper: {
    position: 'relative',
    marginLeft: 'auto',
  },
  bmBurgerButton: {
    position: 'relative',
    width: 'calc(1 * 0.8vw + 1rem)',
    height: 'calc(1 * 0.8vw + 1rem)',
  },
  bmBurgerBars: {
    background: Theme1.colors.text.defaultLight,
  },
  bmBurgerBarsHover: {
    opacity: Theme1.linkHoverOpacity,
  },
  bmCross: {
    background: Theme1.colors.text.defaultLight,
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100vh',
    top: 0,
    width: 'unset',
    minWidth: 200,
  },
  bmMenu: {
    background: Theme1.colors.background.primary,
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em',
  },
  bmMorphShape: {
    fill: Theme1.colors.background.primary,
  },
  bmItemList: {
    padding: '0.8em',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  bmItem: {
    display: 'inline-block',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
    top: 0,
    right: 0,
    width: '100vw',
    height: '100vh',
  },
}

const Menu = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)

  return (
    <div style={ styles.menuWrapper }>
      <MenuComponent
        right
        styles={ styles }
        isOpen={ isMenuOpen }
        onStateChange={ state => setIsMenuOpen(state.isOpen) }
        crossButtonClassName={ 'bm-cross-button-custom' }>
        <MenuItem to="/" text="home" icon={ <FontAwesomeIcon icon={ faHouse } /> } onClick={ () => setIsMenuOpen(false) } />
        <MenuItem to="/loaf" text="loaf" icon={ <SvgBreadLoaf size={ 21 } /> } onClick={ () => setIsMenuOpen(false) } />
      </MenuComponent>
    </div>
  )
}

export default Menu
