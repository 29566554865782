import styled from 'styled-components'

export const ScreenshotContainer = styled.div`
  display: flex;
  flex-direction: ${props => (props.isScreenshotLeft ? 'row' : 'row-reverse')};
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  max-width: 1200px;
  padding: 24px 0;
`

export const ScreenshotImageWrapper = styled.div`
  max-width: 350px;
  min-width: 100px;
  margin: -25px 24px;
`

export const CaptionWrapper = styled.div`
  flex: 1;
  min-width: 200px;
  text-align: left;
  line-height: 1.25;
  padding: 4px 24px;
`

export const CaptionHeaderText = styled.div`
  padding-bottom: 8px;
`
