/* eslint-disable max-len */
import * as React from 'react'
import { useNavigate } from 'react-router'
import homeScreenScreenshot from '../../assets/images/loaf/Loaf_iPhoneX_Home_Screen_11_19_21.png'
import searchTemplatesScreenshot from '../../assets/images/loaf/Loaf_iPhoneX_Search_Templates_11_19_21.png'
import inviteUsersScreenshot from '../../assets/images/loaf/Loaf_iPhoneX_Invite_Users_11_19_21.png'
import Button from '../../components/button/Button'
import Screenshot from '../../components/screenshot/Screenshot'
import { Tagline } from '../home/home.page.styles'
import MobileDownloadButtons from '../../components/mobileDownloadButtons/MobileDownloadButtons'
import { LoafProductPageContainer } from './loaf.product.page.styles'
import GSText from '../../components/gsText/GSText'

const renderHomeScreenScreenshot = () => {
  const captionHeader = 'Your New Grocery Companion'
  const caption = `Loaf is a new way to easily manage your grocery list from anywhere. Say goodbye to misplaced sticky notes and forgotten purchases. With Loaf, you'll always have your grocery list at your fingertips.`

  return (
    <Screenshot screenshotUrl={ homeScreenScreenshot } captionHeader={ captionHeader } caption={ caption } />
  )
}

const renderSearchTemplateScreenshot = () => {
  const captionHeader = 'Add Items With Ease'
  const caption = `To add to your list, simply search for what you want and Loaf will do the rest. Items will be placed automatically in the correct category to keep your list nice and tidy.`

  return (
    <Screenshot screenshotUrl={ searchTemplatesScreenshot } captionHeader={ captionHeader } caption={ caption } />
  )
}

const renderInviteUsersScreenshot = () => {
  const captionHeader = 'Keep Everyone On The Same Page'
  const caption = `From family members to roommates, you can create and invite anyone to your list.  Updates to the list will automatically be shared with your group, keeping everyone in sync.`

  return (
    <Screenshot screenshotUrl={ inviteUsersScreenshot } captionHeader={ captionHeader } caption={ caption } isScreenshotLeft={ false } />
  )
}

const LoafProductPage = () => {
  const navigate = useNavigate();

  return (
    <LoafProductPageContainer>
      <div>
        <Tagline>
          <GSText size="xxl" isLightTheme>
            Introducing Loaf:
          </GSText>
        </Tagline>
        <GSText size="xl" isLightTheme>
          A whole new way to manage your grocery list
        </GSText>
        <MobileDownloadButtons
          appStoreUrl="https://apps.apple.com/us/app/loaf-grocery-list/id1598053675"
          playStoreUrl="https://play.google.com/store/apps/details?id=com.ventricTechnologies.loaf"
        />
      </div>
      { renderHomeScreenScreenshot() }
      { renderInviteUsersScreenshot() }
      { renderSearchTemplateScreenshot() }
      <Button onClick={ () => navigate('/loaf/privacy-policy') }>
        Privacy Policy
      </Button>
    </LoafProductPageContainer>
  )
}

export default LoafProductPage
