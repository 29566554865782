export const Theme1 = {
  colors: {
    background: {
      primary: '#202D40',
      success: 'rgba(40, 167, 69, 1)',
      danger: 'rgba(166, 52, 70, 1)',
      warning: 'rgba(255, 193, 7, 1)',
      level0: 'rgba(255, 255, 255, 1)',
      level1: 'rgba(240, 240, 240, 1)',
      level2: 'rgba(225, 225, 225, 1)',
      level3: 'rgba(210, 210, 210, 1)',
      level4: 'rgba(195, 195, 195, 1)',
      overlay: 'rgba(0.5, 0.25, 0, 0.4)',
    },
    text: {
      warning: 'rgba(52, 58, 64, 1)',
      defaultDark: 'rgba(0, 0, 0, 1)',
      defaultLight: 'rgba(255, 255, 255, 1)',
    },
  },
  headerHeight: 100,
  linkHoverOpacity: 0.8,
}
