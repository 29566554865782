import * as React from 'react'
import { BannerContainer, BannerContent, BannerIcon, BannerInner, BannerName } from './Banner.styles'
import NavLink from '../navlink/NavLink'
import Menu from '../menu/Menu'
import GSText from '../gsText/GSText'
import { faHeartbeat } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Banner = () => (
  <BannerContainer>
    <BannerInner>
      <NavLink to="/">
        <BannerContent>
          <BannerIcon>
            <FontAwesomeIcon icon={ faHeartbeat } fontSize={ 'calc(0.6 * 1.2vw + 1rem)' } />
          </BannerIcon>
          <BannerName>
            <GSText size="m" isHeader isHeavyHeader>
              ventric technologies
            </GSText>
          </BannerName>
        </BannerContent>
      </NavLink>
      <Menu />
    </BannerInner>
  </BannerContainer>
)

export default Banner
