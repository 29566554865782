import styled from 'styled-components';
import { Theme1 } from '../../theme/theme';

export const BannerContainer = styled.div`
  width: 100%;
  background-color: ${Theme1.colors.background.primary};
  position: sticky;
  top: 0;
`

export const BannerInner = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
`

export const BannerContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  &:hover {
    opacity: ${Theme1.linkHoverOpacity};
  }
`

export const BannerIcon = styled.div`
  padding-right: 8px;
`

export const BannerName = styled.div`
  padding-right: 6px;
`
